import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PWA, PRPL Pattern の概要と採用状況の調査",
  "date": "2020-07-17T08:07:11.000Z",
  "slug": "entry/2020/07/17/170711",
  "tags": ["medley"],
  "hero": "./2020_07_17.png",
  "heroAlt": "PWA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。メドレーにてジョブメドレー開発エンジニアをしています、矢野と申します。`}</p>
    <ul>
      <li parentName="ul">{`ジョブメドレーでは、主にバックエンド ( Ruby on Rails ) の改修を担当してます`}</li>
      <li parentName="ul">{`直近では `}<strong parentName="li">{`「サイトパフォーマンス改善施策」`}</strong>{` として、Rails コードのリファクタリングによる TTFB 高速化に取り組んでました`}</li>
      <li parentName="ul">{`「もう絶対にコケないのが分かってる」ビルドやテストを、手元のコンソールで何度も叩いて「わー。ちゃんと通る！」っていう時間が好きです`}</li>
    </ul>
    <p>{`今回は、上記の「サイトパフォーマンス改善施策」の文脈で調査した、PWA の実装パターンである `}<strong parentName="p">{`PRPL Pattern`}</strong>{` という `}<strong parentName="p">{`リソース提供の設計アーキテクチャ`}</strong>{` について紹介します。`}</p>
    <h1>{`PRPL Pattern とは`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200716/20200716154256.png",
      "alt": "20200716154256.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://web.dev/apply-instant-loading-with-prpl/"
        }}>{`Apply instant loading with the PRPL pattern - web.dev`}</a></p>
    </blockquote>
    <p>{`PRPL Pattern は、Google I/O 2016 で提案された `}<strong parentName="p">{`PWA - Progressive Web Application`}</strong>{` の構築・配信のための設計アーキテクチャです。`}</p>
    <p>{`Web サイト・アプリケーションが、回線強度やスペックが高くないスマートフォンなどのデバイスでもストレスなく機能するよう、リソース配信とアプリ起動時のパフォーマンス ( = 高速化 ) に重点を置いています。`}</p>
    <h2>{`PRPL meanings`}</h2>
    <p>{`では具体的に「どうやって速くするの？」ということで、PRPL が提唱している 4 つのサイトレンダリング手法について見ていきます。`}</p>
    <ul>
      <li parentName="ul">{`Push: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<link preload>`}</code>{` および `}<strong parentName="li">{`HTTP/2`}</strong>{` を使用して、初期 URL ルートの重要なリソースを Server Push する`}</li>
      <li parentName="ul">{`Render: クライアントが初期ルートをなるべく早くレンダリングする`}</li>
      <li parentName="ul">{`Pre-cache: 残りのルートをクライアントが `}<strong parentName="li">{`Service Worker`}</strong>{` でプリキャッシュする`}</li>
      <li parentName="ul">{`Lazy load: クライアントはオンデマンドで残りのルートを遅延読込みして作成する`}</li>
    </ul>
    <p>{`PRPL は上記 4 つの頭文字をとったものですね。 PRPL は `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7540"
      }}>{`HTTP/2`}</a>{` の Server Push や、PWA の `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/primers/service-workers"
      }}>{`Service Worker`}</a>{` など、Web プラットフォームの最新技術を駆使してサイトパフォーマンスをあげよう！というプラクティスです。`}</p>
    <blockquote>
      <p parentName="blockquote">{`PWApps とは、最新の Web 技術を有効に活用し、漸進的 （ Progressive ） に高度なユーザー体験を提供しようとする概念です。この PWApps の概念を具体化する一つの手法として、「 PRPL 」 （ パープル ） と名付けられた開発・提供パターンが提案されました。`}</p>
      <p parentName="blockquote">{`(中略)`}</p>
      <p parentName="blockquote">{`Web Components や、 Service Worker、 HTTP/2 Server Push といった Web の最新技術をフルに活用し、レスポンス性の高いユーザー体験を提供しようというものです。`}</p>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://html5experts.jp/komasshu/19704/"
        }}>{`Google が新たに提唱する Progressive Web Apps の新たな開発パターン「 PRPL 」とは？`}</a></p>
    </blockquote>
    <h1>{`周辺知識 - HTTP/2`}</h1>
    <p>{`まずは、周辺知識からおさらいしていきます。PRPL は HTTP/2 の Server Push を利用する、という話でした。そもそも HTTP/2 とはどんなものでしょうか。`}</p>
    <ul>
      <li parentName="ul">{`Hyper Text Transfer Protocol と呼ばれる TCP 上の通信プロトコルの次世代バージョン`}</li>
      <li parentName="ul">{`普段私たちが Web サイトを閲覧する際に利用しているプロトコル`}</li>
      <li parentName="ul">{`HTTP/1.1 が 1997 年に策定され、2015 年にようやく /2 が標準化`}</li>
      <li parentName="ul">{`Express, Apache, Nginx など各 Web サーバ、各ブラウザも対応してきている`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/ja/docs/Web/HTTP/Basics_of_HTTP/Evolution_of_HTTP"
        }}>{`HTTP の進化`}</a></p>
    </blockquote>
    <h2>{`現行の一般的なバージョンは HTTP/1.1`}</h2>
    <p>{`HTTP は普段私たちが Web サイトを閲覧する際に利用する通信プロトコルです。HTTP/2 はその次世代バージョンになります。HTTP/1.1 には以下のような特徴があります。`}</p>
    <ul>
      <li parentName="ul">{`ステートレスな通信`}</li>
      <li parentName="ul">{`テキストベースで情報をやりとりする`}</li>
      <li parentName="ul">{`原則 1 リクエストに対して 1 レスポンスである`}</li>
      <li parentName="ul">{`→ 複数リソースを得るために何度もリクエストしてコネクションを貼り直す必要があり `}<strong parentName="li">{`パフォーマンス上の課題がある`}</strong></li>
    </ul>
    <p>{`これに対して、1.1 の次期バージョンである HTTP/2 は以下のような特徴があります。`}</p>
    <ul>
      <li parentName="ul">{`通信時にヘッダを圧縮し使い回す省エネ設計 = 一部ステートがある`}</li>
      <li parentName="ul">{`バイナリベースで情報をやりとりする`}</li>
      <li parentName="ul">{`ストリームという概念で、1 コネクション中で Request / Response を多重化できる`}</li>
      <li parentName="ul">{`→ `}<strong parentName="li">{`1 コネクションの中で複数リソースを並行して Request / Response できる！`}</strong></li>
      <li parentName="ul">{`リソースの Server Push が可能`}</li>
    </ul>
    <p>{`HTTP/2 自体が HTTP/1.1 の課題であった通信のオーバヘッドを改善する規格であることがわかりますね。また「 request / response の多重化」により、1.1 と比較してどの程度「速く」なるのかについては、以下 Akamai 社のブログサイトが参考になります。`}</p>
    <blockquote>
      <p parentName="blockquote">{`HTTP/1.1`}</p>
    </blockquote>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200716/20200716154352.png",
      "alt": "20200716154352.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`HTTP/2 ( request / response の多重化 )`}</p>
    </blockquote>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200716/20200716154439.png",
      "alt": "20200716154439.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://blogs.akamai.com/jp/2017/03/AdaptiveAcceleration.html"
        }}>{`HTTP/2 を活用するパフォーマンス最適化 ADAPTIVE ACCELERATION`}</a></p>
    </blockquote>
    <h2>{`HTTP/2 の採用事例`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200716/20200716154502.png",
      "alt": "20200716154502.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://caniuse.com/#feat=http2"
        }}>{`caniuse.com`}</a></p>
    </blockquote>
    <p>{`上記対応状況からも分かる通り、モダンブラウザでは一通り対応しています。実際のプロダクションでも 日本ではメルカリさん、世界的なサービスだと Twitter, Facebook, Instagram など SNS サービスや、Slack, Dropbox などが HTTP/2 に対応しているようです。`}</p>
    <p>{`ジョブメドレーはまだ HTTP/1.1 でのサービス提供しか行っていませんが、ゆくゆくはバージョンアップ対応を行っていきたいと思っています。`}</p>
    <h1>{`周辺知識 - PWA`}</h1>
    <p>{`次に、PWA についておさらいします。PRPL は PWA の Service Worker を利用した実装パターンという話でしたが、そもそも PWA や Service Worker とはどんなものなのでしょうか。`}</p>
    <h2>{`PWA - Progressive Web Application`}</h2>
    <ul>
      <li parentName="ul">{`Web プラットフォームの新機能を使って「ネイティブアプリとウェブアプリのいいとこ取りした、UX の高いウェブアプリ」という概念`}</li>
      <li parentName="ul">{`ウェブアプリの特性 ( Secure, Linkable, Indexable … ) を保ちつつ、ネイティブアプリの多機能さ ( インストール、プッシュ通知、オフライン動作 … ) を最新のブラウザ機能 = JavaScript API で実現する`}</li>
      <li parentName="ul">{`必ずしも SPA であったり、最新機能の全てを使っている必要はなく、「斬新的に Web 新 API でネイティブな機能を取り入れていける」というコンセプト`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/ja/docs/Web/Progressive_web_apps/Introduction"
        }}>{`プログレッシブウェブアプリの紹介 - MDN`}</a></p>
    </blockquote>
    <h2>{`PWA を構成する新機能たち`}</h2>
    <ul>
      <li parentName="ul">{`HTTPS: HyperText Transfer Protocol Secure 、 SSL / TLS による通信の暗号化`}</li>
      <li parentName="ul">{`Service Worker: Web ページで動作するスクリプトから独立したイベント駆動型の worker`}</li>
      <li parentName="ul">{`Cache API: Request / Response オブジェクトのストレージキャッシュ`}</li>
      <li parentName="ul">{`Push API / Notifications API: サーバーからアプリへの通知送信`}</li>
      <li parentName="ul">{`マニフェスト: アプリストアを通さず Web アプリをホーム画面にインストール可能`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/ja/docs/Web/Progressive_web_apps"
        }}>{`プログレッシブウェブアプリ - MDN`}</a></p>
    </blockquote>
    <p>{`上記以外にも、PWA には様々な API ・機能が存在します。その中でも PWA の、そして PRPL アーキテクチャの中核を成す重要な機能が `}<strong parentName="p">{`Service Worker`}</strong>{` です。`}</p>
    <h2>{`Service Worker とは`}</h2>
    <ul>
      <li parentName="ul">{`ブラウザのバックグラウンドプロセスとして動作する Worker`}</li>
      <li parentName="ul">{`Web ページで動作するスクリプトとは独立して動作する`}</li>
      <li parentName="ul">{`サーバサイドでいうところの Worker プロセスと同じような使い方ができる`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://developers.google.com/web/fundamentals/primers/service-workers"
        }}>{`Service Worker の紹介`}</a></p>
    </blockquote>
    <p>{`Web ページの JavaScript プロセスとは切り離された文脈で、予め登録しておいた処理を、様々なイベントに応じて発火させることができるイメージですね。`}</p>
    <p>{`プッシュ通知など、いわゆる「ネイティブアプリのような機能」は、この Service Worker を利用することで実現しています。`}</p>
    <h2>{`PWA の採用状況`}</h2>
    <p>{`Google からの提唱当初 ( PWA も Google のプロジェクトです ) こそ、先進的すぎてなかなか受け入れられなかった PWA ですが、2020 年現在は各ブラウザの対応状況も少しずつ向上されています。`}</p>
    <p>{`日本では SUUMO、日経電子版、一休.com、世界的なサービスだと Instagram などが PWA による Web サイトを提供しているようです。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://markezine.jp/article/detail/23623"
        }}>{`リクルートの『SUUMO』、Android スマートフォン用サイトでプッシュ通知できる機能を実装`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://employment.en-japan.com/engineerhub/entry/2018/06/05/110000"
        }}>{`PWA で表示速度が 2 倍に！ スピード改善を妥協しない日経電子版に学ぶ、PWA のメリット＆デメリット`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.agektmr.com/2018/03/instagram-pwa.html"
        }}>{`なぜ Instagram は PWA を作ったのか？`}</a></li>
    </ul>
    <p>{`特に、既にネイティブアプリで大成功している Instagram が、回線・端末スペックの低い新興国をターゲットとした PWA をリリースしているという点はプロダクト観点からもとても興味深いですね。`}</p>
    <h1>{`PRPL パターンの利点`}</h1>
    <p>{`さて、話を戻して PRPL パターンが HTTP/2 や Service Worker を使って、具体的にどのようにサイトパフォーマンスを向上するのか？という点を見ていきます。`}</p>
    <h2>{`Server Push + Service Worker による Pre-cache`}</h2>
    <p>{`PRPL の 4 要素を、改めてもう少しわかりやすく記載してみると以下のようになります。`}</p>
    <ul>
      <li parentName="ul">{`Push`}
        <ul parentName="li">
          <li parentName="ul">{`初回コネクションで `}<strong parentName="li">{`HTTP/2 Server Push で 必要リソースをまとめて Push`}</strong></li>
        </ul>
      </li>
      <li parentName="ul">{`Render`}
        <ul parentName="li">
          <li parentName="ul">{`上記で受け取った HTML リソースを元に初期画面をレンダリングする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Pre-cache`}
        <ul parentName="li">
          <li parentName="ul">{`上記初期画面で利用されるリソースは、`}<strong parentName="li">{`Server Push により非同期的に Service Worker が Pre-cache する`}</strong></li>
          <li parentName="ul">{`また、今後利用しそうな追加リソースについても、非同期・投機的に Service Worker が事前に DL 、キャッシュする`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Lazy load`}
        <ul parentName="li">
          <li parentName="ul">{`初期画面以降で必要になった画像などのリソースを、画面スクロールなどを検知し、表示に必要になったタイミングで遅延読み込みする`}</li>
        </ul>
      </li>
    </ul>
    <p>{`上記の太字箇所を画像で説明すると、以下のようになります。`}</p>
    <blockquote>
      <p parentName="blockquote">{`HTTP/2 ( Server Push )`}</p>
    </blockquote>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200716/20200716154542.png",
      "alt": "20200716154542.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://blogs.akamai.com/jp/2017/03/AdaptiveAcceleration.html"
        }}>{`HTTP/2 を活用するパフォーマンス最適化 ADAPTIVE ACCELERATION`}</a></p>
    </blockquote>
    <p>{`HTTP/2 の Request / Response の多重化だけを利用したケースと比較すると、「ブラウザがページを解析して、必要リソースを Request する」よりも前に「サーバが必要リソースを強制的に Push 」しているのがわかります。`}</p>
    <p>{`この Push されたリソースを Service Worker が受け取り → キャッシュ化することで `}<strong parentName="p">{`「ページ解析が終わった時点では既に必要リソースがブラウザにキャッシュされている」`}</strong>{` 状態となり、アプリの初回起動が速くなる、というのが Push & Pre-Cache の速度改善の仕組みです。`}</p>
    <h2>{`Service Worker で必要になりそうなリソースの事前キャッシュ`}</h2>
    <p>{`また、初期画面に必ず必要なリソース以外については、「このあと必要になりそう・なるはずの追加リソース」ということで、Service Worker に投機的に事前 DL → Pre-cache されることも可能です。`}</p>
    <p>{`このあたりのキャッシュ戦略・導入事例は以下の一休さんの記事が詳しいです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://user-first.ikyu.co.jp/entry/2019/12/02/080000"
      }}>{`一休.com に Service Worker(Workbox)を導入しました`}</a></p>
    <h1>{`PRPL Pattern の採用状況`}</h1>
    <p>{`さて、そんなパフォーマンスに嬉しい PRPL Pattern ですが、HTTP/2、PWA 自体の普及率も高くなくまだまだプロダクションでの採用事例は少ない印象です。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://hack.nikkei.com/blog/nikkei-featured-at-google-io/"
        }}>{`Google I/O で日経電子版が事例として紹介された話`}</a>
        <ul parentName="li">
          <li parentName="ul">{`PRPL パターンを参考にした Service Worker を使ったキャッシュ、HTTP/2 Push でのリソース配信などが採用されている`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ライブラリでは有名どころだと Gatsby が標準対応、当たり前だが Google の Polymer ライブラリも PRPL パターンで実装されている`}</li>
    </ul>
    <p>{`とはいえ、PWA 化している Web サイトであればパターンの適用はそこまで難しくありません。`}</p>
    <p>{`また Next.js の PWA 化ライブラリ `}<a parentName="p" {...{
        "href": "https://github.com/shadowwalker/next-pwa"
      }}>{`next-pwa`}</a>{` では、Next.js 本体の Code Splitting 機能と連携した「 Service Worker での追加コード読み込み」をサポートするなど、このようなアーキテクチャパターンの潮流は今後も派生していくのかな？という気がしています。`}</p>
    <h1>{`まとめ - HTTP/2 + PWA + PRPL Pattern`}</h1>
    <p>{`まとめです。`}</p>
    <ul>
      <li parentName="ul">{`PWA とは`}
        <ul parentName="li">
          <li parentName="ul">{`Web プラットフォームの新機能を使った「ネイティブアプリとウェブアプリのいいとこ取りした UX の高いウェブアプリ」という概念`}</li>
        </ul>
      </li>
      <li parentName="ul">{`PRPL パターンとは`}
        <ul parentName="li">
          <li parentName="ul">{`スマートフォンなど回線強度・スペックの低いデバイスのために、Google が UX 向上のために提唱する PWA の設計アーキテクチャ`}</li>
          <li parentName="ul">{`HTTP/2, Service Worker などを使って (リソースの) Server push, Pre-cache, Lazy load を行う`}</li>
        </ul>
      </li>
      <li parentName="ul">{`プロダクトで使えるのか`}
        <ul parentName="li">
          <li parentName="ul">{`Web サイトの PWA をする／しているのであれば、サーバの HTTP/2 化をして、リソースの Push、Pre-cache を導入するのはパフォーマンス観点で十分検討できるのでは`}</li>
          <li parentName="ul">{`但し、SPA + SSR 構成のサイトでは、Next.js などフレームワークのコード分割に寄せるのが今の所無難そうではある`}</li>
        </ul>
      </li>
    </ul>
    <p>{`今回は調査のみで、プロダクトへの実践投入は行いませんでしたが、今後プロダクトの PWA 化が企画されるような場合は、ぜひ導入してみたい技術だなと感じました。`}</p>
    <p>{`以上、ここまで読んでくださり、ありがとうございました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      